export default defineNuxtRouteMiddleware((to, from) => {
    console.log('from.name: ' + JSON.stringify(from.name))
    console.log('from.redirectedFrom: ' + from.redirectedFrom)
    // if (isAuthenticated() === false) {
    //     return navigateTo('/login')
    // }
    // console.log('from.meta: ' + JSON.stringify(from.meta))
    // console.log('from.fullPath: ' + from.fullPath)
    // console.log('from.path: ' + from.path)
    // console.log('to.param: ' + JSON.stringify(to.params))
    // console.log('to.fullPath: ' + JSON.stringify(to.fullPath))
    // console.log('to.meta: ' + JSON.stringify(to.meta))
    // console.log('to.name: ' + JSON.stringify(to.name))
    
})